import React, { useState,useRef } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import withStyles from "@material-ui/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import useTheme from "@material-ui/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import GetAppIcon from "@material-ui/icons/GetApp";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import api from "../../common/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import html2canvas from "html2canvas";

import ReportInput from "./components/SingleBatchReportInput";
import { useReactToPrint } from 'react-to-print';
import {useGlobalState} from '../../state';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TableWrapper from "../../components/Table/TableWrapper";
import { generateReportTitle } from "./utils/functions";

window.html2canvas = html2canvas;
const useStyles = makeStyles(theme => ({
  root: props => ({
    margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
  }),
  topBar: {
    marginBottom: theme.spacing(2)
  },
  link: {
    display: "flex"
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20
  },
  formControl: {
    width: "100%"
  },
  progressContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  },
  progressBar: {
    margin: theme.spacing(2)
  },
  singleLineBorder: {
    borderTop: "solid 1px #F26522",
    borderBottom: "solid 1px #F26522"
  },
  doubleLineBorder: {
    borderTop: "double #F26522",
    borderBottom: "double #F26522"
  },
  reportContainer: {},
  reportHeader: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  rawBatchName: {
    textAlign: "center",
    textDecoration: "underline"
  }
}));

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding:"6px",
    overflow: "hidden", 
    width:"90px"
  },
  body: {
    fontSize: "1em",
    backgroundColor: theme.palette.common.white,
    padding:"6px",
    overflow: "hidden", 
    width:"90px"
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  }
}))(TableRow);

const tableHeader = JSON.parse(
  `[["Date","Age(in weeks)","Age(in days)", "Closing Birds", "Egg Production(in Trays)", "Broken Eggs(in Trays)", "Damaged Eggs(in Trays)", "Production %", "Good Egg %", "Broken Egg %", "Damaged Egg %", "Feed Intake in Kg", "Feed/bird in grams",
    "Egg/bag", "Mortality", "Std Egg %", "Std.Feed/bird in grams"]]`
);

const generateExcel = function(header, data, fileName="report") {
  let csvData = "";
  header.forEach((column, index) => {
    if (index === 0) csvData = csvData + column;
    else csvData = csvData + "," + column;
  });
  csvData = csvData + "\r\n";
  data.forEach(row => {
    row.forEach((column, index) => {
      if (index === 0) csvData = csvData + column;
      //else if (index === 1) csvData = csvData + "";
      else csvData = csvData + "," + column;
    });
    csvData = csvData + "\r\n";
  });

  var element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/csv;charset=utf-8," + encodeURIComponent(csvData)
  );
  element.setAttribute("download", `${fileName}.csv`);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

const SingleBatch = ({ showError, match }) => {
  const [{user}] = useGlobalState();
  const theme = useTheme();
  const [batch, setBatch] = useState("");
  const [shedType, setShedType] = useState("");
  /*const [{user}] = useGlobalState();
    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }*/

  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);
  const [data, setData] = useState([]);
  
  const [column, setColumn] = useState({
    stdData: false,
    diffData: false,
    truckEggsView: user?.Config?.truckeggs_as_goodEggs || false,
  });
  const [reportFileName, setReportFileName] = useState("");

  const parseReportFromServer = function(data) {
    const { reportsArray } = data;
    setData(reportsArray);
    console.log(data);
  };

/*  const numToIndianStd = function (data){
    if(!isNaN(data)){
    return data.toLocaleString(user.locales,amountObj);
    }
    };*/

  const getChosenBatch = function(chosenValue) {
    setBatch(chosenValue);
  };

  const getChosenShedType = function(chosenValue) {
    setShedType(chosenValue);
  };

  const generateReport = function() {
    showError("");
    setLoading(true);
    const payload = {
      //batchID: batches[batchIndex].id
      batchID: batch.id,
      farmID: parseInt(match.params["farmID"]),
      shedTypeID: shedType.id
    };

    const response = api.post("reports/single-batch", payload);

    response
      .then(res => {
        if (res.reportsArray) {
          if (res.reportsArray.length < 1){
            showError("No data found");
            return
          }
          parseReportFromServer(res);
        } else {
          setData([])
        }
        setReportFileName(generateReportTitle({batch, shedType, reportName: "SingleBatchKPI"}));
      })
      .catch(err => {
        if (err.message) {
          if (err.message === "Bad Request" || err.message === "No data found")
            showError(err.message);
        } else {
          showError("Unknown Error");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true
  });
  const classes = useStyles({ isDesktop });

  const componentRef = useRef();
    const generatePDF = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: reportFileName,
        pageStyle: `
          @media print {
            @page {
              size: landscape !important;
              margin: 0;
            }
          }`,
      });


  return (
    <div className={classes.root}>
      <Grid container alignItems="flex-start" className={classes.topBar}>
        <Grid>
          <Typography display="block" variant="h3" gutterBottom>
            Single Batch KPI Report
          </Typography>
        </Grid>
      </Grid>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container spacing={3} justify="flex-start" alignItems="center">
          <Grid  xs={isDesktop ? 5 :12}  item>
            <ReportInput
              showError={showError}
              match={match}
              setValid={setValid}
              getChosenBatch={getChosenBatch}
              getChosenShedType={getChosenShedType}
              setLoading={setLoading}
            />
          </Grid>
          <Grid item>
            <Button
              disabled={!valid}
              variant="contained"
              color="primary"
              onClick={generateReport}
            >
              Generate
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={!valid || data.length < 1}
              variant="outlined"
              color="primary"
              onClick={generatePDF}
              endIcon={<PictureAsPdfIcon />}
            >
              Download PDF
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={!valid || data.length < 1}
              variant="outlined"
              color="primary"
              onClick={() => generateExcel(tableHeader, data, reportFileName)}
              endIcon={<GetAppIcon />}
            >
              Download Excel
            </Button>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
      <br />
      <div>
        <FormControlLabel
          control={
            <Switch
              checked={column.stdData}
              onChange={() => setColumn({
                ...column,
                stdData: !column.stdData
              })}
              color="primary"
            />
          }
          label="Std Data"
        />
        <FormControlLabel
          control={
            <Switch
              checked={column.diffData}
              onChange={() => setColumn({
                ...column,
                diffData: !column.diffData
              })}
              color="primary"
            />
          }
          label="Difference Data"
        />
                  <FormControlLabel
          control={
            <Switch
              checked={column.truckEggsView}
              onChange={() => setColumn({
                ...column,
                truckEggsView: !column.truckEggsView
              })}
              color="primary"
            />
          }
          label="Truck Eggs (Broken & Damage) in Good Eggs"
        />
      </div>
      {loading ? (
        <div className={classes.progressContainer}>
          <CircularProgress className={classes.progressBar} />
        </div>
      ) : (
        <div id="report-container" className="reportPdfContainer" ref={componentRef}>
          <div className={classes.reportHeader}>
            <Typography
              variant="h4"
              component="h4"
              className={classes.rawBatchName}
            >
              {batch.id && batch.name !== ""
                ? `Single Batch Report ${batch.name}` +
                  (shedType !== "" ? ` at ${shedType.shed_type}` : "")
                : ""}
            </Typography>
          </div>
          <TableWrapper enableKeyboardNavigation={true} focus={data && data.length > 0} >
          <Table style={{ tableLayout: "auto", maxWidth: "100%"}} stickyHeader aria-label="sticky table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Age (in weeks)</StyledTableCell>
                <StyledTableCell>Age (in days)</StyledTableCell>
                <StyledTableCell>Closing Bird</StyledTableCell>
                <StyledTableCell>Egg Production </StyledTableCell>
                <StyledTableCell>Broken Eggs </StyledTableCell>
                <StyledTableCell>Damaged Eggs </StyledTableCell>
                <StyledTableCell>Production %</StyledTableCell>
                <StyledTableCell>Good Egg %</StyledTableCell>
                <StyledTableCell>Broken Egg %</StyledTableCell>
                <StyledTableCell>Damaged Egg %</StyledTableCell>
                <StyledTableCell>Feed Intake in Kg</StyledTableCell>
                <StyledTableCell>Feed/bird in grams</StyledTableCell>
                <StyledTableCell>Egg/bag</StyledTableCell>
                <StyledTableCell>Mortality</StyledTableCell>
                {
                  (column.stdData) && 
                  <>
                      <StyledTableCell>Std Egg %</StyledTableCell>
                      <StyledTableCell>Std. Feed/bird (in grams)</StyledTableCell>
                  </>
                }
                {
                  (column.diffData) &&
                  <>
                      <StyledTableCell>Diff. Egg %</StyledTableCell>
                      <StyledTableCell>Diff. Feed/bird (in grams)</StyledTableCell>
                  </>
                }
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell style={{ fontWeight: "bold" }} align="left">
                    {row[0] ? row[0] : ""}
                  </StyledTableCell>
                  <StyledTableCell align="left">{row[1]}</StyledTableCell>
                  <StyledTableCell align="left">{row[2]}</StyledTableCell>
                  <StyledTableCell align="left"> {row[3]}</StyledTableCell>

                  {
                     (column.truckEggsView) ?
                     <StyledTableCell align="left">{row[21]}</StyledTableCell>
                     :
                     <StyledTableCell align="left">{row[4]}</StyledTableCell>

                  }

                  {
                     (column.truckEggsView) ?
                     <StyledTableCell align="left">{row[19]}</StyledTableCell>
                     :
                     <StyledTableCell align="left">{row[5]}</StyledTableCell> 

                  }

                  {
                     (column.truckEggsView) ?
                     <StyledTableCell align="left">{row[20]}</StyledTableCell>
                     :
                     <StyledTableCell align="left"> {row[6]}</StyledTableCell>

                  }

                  <StyledTableCell align="left">{row[7]}</StyledTableCell>
                  {
                     (column.truckEggsView) ?
                     <StyledTableCell align="left">{row[22]}</StyledTableCell>
                     :
                     <StyledTableCell align="left">{row[8]}</StyledTableCell>

                  }

                  {
                     (column.truckEggsView) ?
                     <StyledTableCell align="left">{row[23]}</StyledTableCell>
                     :
                     <StyledTableCell align="left">{row[9]}</StyledTableCell>

                  }
                   {
                     (column.truckEggsView) ?
                     <StyledTableCell align="left">{row[24]}</StyledTableCell>
                     :
                     <StyledTableCell align="left"> {row[10]}</StyledTableCell>

                  }

                  <StyledTableCell align="left"> {row[11]}</StyledTableCell>
                  <StyledTableCell align="left">{row[12]}</StyledTableCell>
                  <StyledTableCell align="left"> {row[13]}</StyledTableCell>
                  <StyledTableCell align="left"> {row[14]}</StyledTableCell>
                  {
                  (column.stdData) && 
                  <>
                  <StyledTableCell align="left"> {row[15]}</StyledTableCell>
                  <StyledTableCell align="left"> {row[16]}</StyledTableCell>
                  </>
                  }
                  {
                    (column.diffData) &&
                    <>
                       <StyledTableCell align="left"> {row[17]}</StyledTableCell>
                       <StyledTableCell align="left"> {row[18]}</StyledTableCell>
                    </>
                  }
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          </TableWrapper>
        </div>
      )}
    </div>
  );
};

export default SingleBatch;
