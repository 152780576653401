import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { RouteWithLayout } from "./components";
import {
  Farm as FarmLayout,
  Main as MainLayout,
  Minimal as MinimalLayout,
} from "./layouts";

import { useGlobalState } from "./state";
import {
  Activate as ActivateView,
  Batches as BatchView,
  Breed as BreedView,
  ChangePassword as ChangePasswordView,
  DailyEntry as DailyEntryView,
  InvoiceSetting as InvoiceSettingsView,
  DailyEntryFeed as DailyEntryFeedView,
  Dashboard as DashboardView,
  FarmDashBoard as FarmDashBoardView,
  FarmsDashBoard as FarmsDashBoardView,
  FeedFormulation as FeedFormulationView,
  FeedFormulationMaterial as FeedFormulationMaterialView,
  FeedFormulationMedicine as FeedFormulationMedicineView,
  Forbidden as ForbiddenView,
  NotFound as NotFoundView,
  Shed as ShedView,
  SignIn as SignInView,
  SignUp as SignUpView,
  StandardProduction as StdProdView,
  MaterialMaster as MaterialMasterView,
  CustomerVendorDetails as CustomerVendorDetailsView,
  DailyEggRates as DailyEggRatesView,
  Purchase as PurchaseView,
  LinePurchase as LinePurchaseView,
  Sale as SaleView,
  LineSale as LineSaleView,
  LineSalesDriver as LineSalesDriverView,
  CustomerBulkPayments as CustomerBulkPaymentsView,
  SuccessRegistration as RegSuccessView,
  VaccinationSchedule as VacScheduleView,
  ExpenseType as ExpenseTypeView,
  IncomeType as IncomeTypeView,
  OtherExpense as OtherExpenseView,
  OtherIncome as OtherIncomeView,
  BankTransaction as BankTransactionView,
  RawMaterialsReport as RawMaterialsReportView,
  Bank as BankView,
  LineName as LineNameView,
  SingleBatchStockReport as SingleBatchStockReportView,
  SingleBatchReport as SingleBatchReportView,
  AllBatchDailyReport as AllBatchDailyReportView,
  AllBatchStockReport as AllBatchStockReportView,
  SingleBatchWeeklyReport as SingleBatchWeeklyReportView,
  AllBatchWeeklyReport as AllBatchWeeklyReportView,
  DailyProfitLossReport as DailyProfitLossView,
  StockStatementReport as StockStatementReportView,
  // BatchStockStatementReport as BatchStockStatementReportView,
  AllBatchStockStatementReport as AllBatchStockStatementReportView,
  Invoice as InvoiceView,
  BatchShedAvailability as BatchShedAvailabilityView,
  TrailBalanceReport as TrailBalanceReportView,
  LineReport as LineReportView,
  LineSaleReport as LineSaleReportView,
  DayBookReport as DayBookReportView,
  VendorReport as VendorReportView,
  CustomerReport as CustomerReportView,
  VendorBulkPayments as VendorBulkPaymentsView,
  ReconciliationReport as ReconciliationReportView,
  StorageReport as StorageReportView,
  MaterialNutritionProfile as MaterialNutritionProfileView,
  AccountsDashboard as AccountsDashboardView,
  SubUser as SubUserView,
  Subscriptions as SubscriptionsView,
  Subscribers as SubscribersView,
  CustomerTDSReport as CustomerTDSReportView,
  VendorTDSReport as VendorTDSReportView,
  VendorBillBalanceReport as VendorBillBalanceReportView,
  StockAdjustment as StockAdjustmentView,
  VendorYearlyTaxReport as VendorYearlyTaxReportView,
  CustomerYearlyTaxReport,
  LabourReport,
  LineSaleEntries as LineSaleEntriesView,
  // EggsProductionReport as EggsProductionReportView,
  LineSaleBulkPayments as LineSaleBulkPaymentsView,
  LinePurchaseBulkPayments as LinePurchaseBulkPaymentsView,
  StockAvailabilityReport as StockAvailabilityReportView,
  CustomerBillBalanceReport as CustomerBillBalanceReportView,
  medicineStock,
  FeedStorageStock,
  TaxPayments as TaxPaymentsView,
  Truck as TruckView,
  Expenses as ExpensesView,
  ConfigView as Configuration,
  CommissionReport as CommissionReportView,
} from "./views";
import DoctorProfilepage from "./views/ProfilePage/DoctorProfilePage";
import Profilepage from "./views/ProfilePage/Profilepage";
import TaxProfiles from "./views/taxProfiles/taxProfiles";
import BankStatementReport from './views/Reports/BankStatementReport'
import AvailableStock from "./views/AvailableStock/availableStock";
import labourPageView from "./views/Labour/labourPage"
import FeedformulationStorage from "./views/FeedFormulationStorage/FeedFormulationStoragePage";
import RawMaterialStorage from "./views/RawMaterialStorage/RawMaterialStoragePage";
import RawMaterialStorageStock from "./views/RawMaterialStock/rawMaterialStorage";
import labourSectionView from "./views/LabourSection/LabourView"
import labourAttendanceView from "./views/LabourAttendance/LabourAttendance"
import FarmStorage from "./views/FarmStorage/farmStorage";
import FarmStorageReport from "./views/FarmStorage/farmStorageReport"
import LabourPaymentView from "./views/LabourPayment/LabourPayment"
const Routes = () => {
  const [{ user },dispatch] = useGlobalState();

  if(user !== null && ( user.Config === undefined || user.Config === null || user.Config.lines_sales === undefined)){
    dispatch({type: 'logout'})
  }

  return (
    <Switch>
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={AccountsDashboardView}
        exact
        layout={MainLayout}
        path="/accounts-dashboard"
      />
      <RouteWithLayout
        component={BankTransactionView}
        exact
        layout={MainLayout}
        path="/bank-transaction"
      />
      <RouteWithLayout
        component={FarmsDashBoardView}
        exact
        layout={MainLayout}
        path="/farm"
      />
      <RouteWithLayout
        component={FarmDashBoardView}
        exact
        layout={FarmLayout}
        path="/farm/:farmID/dashboard"
      />
      <RouteWithLayout
        component={ShedView}
        exact
        layout={FarmLayout}
        path="/farm/:farmID/shed"
      />
      <RouteWithLayout
        component={DailyEntryView}
        exact
        layout={FarmLayout}
        path="/farm/:farmID/daily-entry"
      />
      <RouteWithLayout
        component={medicineStock}
        exact
        layout={FarmLayout}
        path="/farm/:farmID/medicine-stock"
      />
       <RouteWithLayout
        component={FeedStorageStock}
        exact
        layout={MainLayout}
        path="/feed-storage-stock"
      />
      <RouteWithLayout
        component={RawMaterialStorageStock}
        exact
        layout={MainLayout}
        path="/raw-material-storage-stock"
      />
      <RouteWithLayout
        component={AvailableStock}
        exact
        layout={FarmLayout}
        path="/farm/:farmID/available-stock"
      />
      <RouteWithLayout
        component={FarmStorage}
        exact
        layout={FarmLayout}
        path="/farm/:farmID/farmStorage"
      />
      <RouteWithLayout
        component={FarmStorageReport}
        exact
        layout={FarmLayout}
        path="/farm/:farmID/farmStorageReport"
      />


      <RouteWithLayout
        component={BatchView}
        exact
        layout={FarmLayout}
        path="/farm/:farmID/batch"
      />

      <RouteWithLayout
        component={BreedView}
        exact
        layout={MainLayout}
        path="/breed"
      />
      <RouteWithLayout
        component={Profilepage}
        exact
        layout={MainLayout}
        path="/profilepage"
      />
      <RouteWithLayout
        component={DoctorProfilepage}
        exact
        layout={MainLayout}
        path="/doctorprofilepage"
      />
      <RouteWithLayout
        component={MaterialMasterView}
        exact
        layout={MainLayout}
        path="/material_master"
      />
      <RouteWithLayout
        component={InvoiceSettingsView}
        exact
        layout={MainLayout}
        path="/invoice_settings"
      />
      <RouteWithLayout
        component={labourSectionView}
        exact
        layout={FarmLayout}
        path="/farm/:farmID/labour"
      />

      {user !== null && user.Config.lines_sales?(
          <RouteWithLayout
            component={CustomerVendorDetailsView}
            exact
            layout={MainLayout}
            path="/line-customer-vendor"
          />
        ):null}
      <RouteWithLayout
        component={CustomerVendorDetailsView}
        exact
        layout={MainLayout}
        path="/customer-vendor-details"
      />
      <RouteWithLayout
        component={DailyEggRatesView}
        exact
        layout={MainLayout}
        path="/daily-egg-rates"
      />
      <RouteWithLayout
        component={PurchaseView}
        exact
        layout={MainLayout}
        path="/purchase"
      />
      {user !== null && user.Config.lines_sales?(
        <RouteWithLayout
          component={LinePurchaseView}
          exact
          layout={MainLayout}
          path="/line-purchase"
        />
        ):null}
      <RouteWithLayout
        component={SaleView}
        exact
        layout={MainLayout}
        path="/sale"
      />
      {user !== null && user.Config.lines_sales?(
        <RouteWithLayout
          component={LineSaleView}
          exact
          layout={MainLayout}
          path="/line-sale"
        />
        ):null}
      {user !== null && user.role === "DRIVER" ? 
      <RouteWithLayout
        component={LineSalesDriverView}
        exact
        layout={MainLayout}
        path="/line-sale-driver"
      />
      : null}
      
      {user !== null && user.Config.lines_sales?(
        <RouteWithLayout
          component={TruckView}
          exact
          layout={MainLayout}
          path="/line-sale-truck"
        />
        ):null}
      {user !== null && user.Config.lines_sales?(
        <RouteWithLayout
          component={LineSaleBulkPaymentsView}
          exact
          layout={MainLayout}
          path="/line-sale-bulk-payments"
        />
        ):null}
        {user !== null && user.Config.lines_sales?(
          <RouteWithLayout
            component={LinePurchaseBulkPaymentsView}
            exact
            layout={MainLayout}
            path="/line-purchase-bulk-payments"
          />
        ):null}
        {user !== null && user.Config.feed_section?(
            <RouteWithLayout
                component={FeedFormulationView}
                exact
                layout={MainLayout}
                path="/feed-formulation"
              />
        ):null}
      <RouteWithLayout
        component={FeedFormulationMedicineView}
        exact
        layout={MainLayout}
        path="/feed-formulation/:feedFormulationID/feed-formulation-medicine"
      />
      <RouteWithLayout
        component={FeedformulationStorage}
        exact
        layout={MainLayout}
        path="/feed-formulation-storage"
      />
       <RouteWithLayout
        component={RawMaterialStorage}
        exact
        layout={MainLayout}
        path="/raw-material-storage"
      />
      <RouteWithLayout
        component={VacScheduleView}
        exact
        layout={MainLayout}
        path="/breed/:breedID/vaccination-schedule"
      />
      <RouteWithLayout
        component={FeedFormulationMaterialView}
        exact
        layout={MainLayout}
        path="/feed-formulation/:feedFormulationID/feed-formulation-material"
      />
      <RouteWithLayout
        component={StdProdView}
        exact
        layout={MainLayout}
        path="/breed/:breedID/std-prod"
      />
      {user !== null && user.Config.feed_section?(
          <RouteWithLayout
            component={DailyEntryFeedView}
            exact
            layout={MainLayout}
            path="/daily-entry-feed"
          />
        ):null}
      <RouteWithLayout
        component={ExpenseTypeView}
        exact
        layout={MainLayout}
        path="/expense-type"
      />
      <RouteWithLayout
        component={IncomeTypeView}
        exact
        layout={MainLayout}
        path="/income-type"
      />
      <RouteWithLayout
        component={OtherExpenseView}
        exact
        layout={MainLayout}
        path="/other-expense"
      />
      <RouteWithLayout
        component={OtherIncomeView}
        exact
        layout={MainLayout}
        path="/other-income"
      />
      <RouteWithLayout
        component={RawMaterialsReportView}
        exact
        layout={MainLayout}
        path="/reports/rawmaterials"
      />
      <RouteWithLayout
        component={StockStatementReportView}
        exact
        layout={MainLayout}
        path="/reports/stockStatement"
      />
      <RouteWithLayout
        component={StockAvailabilityReportView}
        exact
        layout={MainLayout}
        path="/reports/stockAvailability"
      />
      <RouteWithLayout
        component={SingleBatchStockReportView}
        exact
        layout={FarmLayout}
        path="/farm/:farmID/reports/single-batch-stock"
      />
      <RouteWithLayout
        component={SingleBatchReportView}
        exact
        layout={FarmLayout}
        path="/farm/:farmID/reports/single-batch"
      />
      <RouteWithLayout
        component={SingleBatchWeeklyReportView}
        exact
        layout={FarmLayout}
        path="/farm/:farmID/reports/single-batch-weekly"
      />
      <RouteWithLayout
        component={AllBatchDailyReportView}
        exact
        layout={FarmLayout}
        path="/farm/:farmID/reports/all-batch-daily"
      />
      <RouteWithLayout
        component={AllBatchStockReportView}
        exact
        layout={FarmLayout}
        path="/farm/:farmID/reports/all-batch-stock"
      />

      <RouteWithLayout
        component={DailyProfitLossView}
        exact
        layout={FarmLayout}
        path="/farm/:farmID/reports/daily-profit-loss"
      />
       <RouteWithLayout
        component={AllBatchWeeklyReportView}
        exact
        layout={FarmLayout}
        path="/farm/:farmID/reports/all-batch-weekly"
      />
       <RouteWithLayout
        component={labourPageView}
        exact
        layout={FarmLayout}
        path="/farm/:farmID/labour-page"
      />     
      <RouteWithLayout
        component={labourAttendanceView}
        exact
        layout={FarmLayout}
        path="/farm/:farmID/labour-Attendance"
      />
       <RouteWithLayout
        component={LabourPaymentView}
        exact
        layout={FarmLayout}
        path="/farm/:farmID/labour-payment"
      />

      <RouteWithLayout
          component={LabourReport}
          exact
          layout={FarmLayout}
          path="/farm/:farmID/labour-report"
        />
      {/* <RouteWithLayout
        component={BatchStockStatementReportView}
        exact
        layout={FarmLayout}
        path="/farm/:farmID/reports/batch-stock-statement"
      /> */}

      <RouteWithLayout
        component={AllBatchStockStatementReportView}
        exact
        layout={FarmLayout}
        path="/farm/:farmID/reports/all-batch-stock-statement"
      />
      {/* <RouteWithLayout
        component={EggsProductionReportView}
        exact
        layout={FarmLayout}
        path="/farm/:farmID/reports/eggs-production"
      /> */}

      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
        skipLogin={true}
      />
      <RouteWithLayout
        component={ForbiddenView}
        exact
        layout={MinimalLayout}
        path="/forbidden"
        skipLogin={true}
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
        skipLogin={true}
      />
      <RouteWithLayout
        component={RegSuccessView}
        exact
        layout={MinimalLayout}
        path="/success-registration"
        skipLogin={true}
      />
      <RouteWithLayout
        component={ActivateView}
        exact
        layout={MinimalLayout}
        path="/activate/:key"
        skipLogin={true}
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
        skipLogin={true}
      />
      <RouteWithLayout
        component={ChangePasswordView}
        exact
        layout={MinimalLayout}
        path="/change-password/:key"
        skipLogin={true}
      />

      <RouteWithLayout
        component={BankView}
        exact
        layout={MainLayout}
        path="/bank"
      />
      <RouteWithLayout
        component={LineNameView}
        exact
        layout={MainLayout}
        path="/line_name"
      />
     {user !== null && user.Config.feed_section?(
          <RouteWithLayout
            component={MaterialNutritionProfileView}
            exact
            layout={MainLayout}
            path="/material-nutrition-profile"
          />
        ):null}
      <RouteWithLayout
        component={SubUserView}
        exact
        layout={MainLayout}
        path="/subuser"
      />
      <RouteWithLayout
        component={InvoiceView}
        exact
        layout={MainLayout}
        path="/invoice/:saleID"
      />
      <RouteWithLayout
        component={BatchShedAvailabilityView}
        exact
        layout={FarmLayout}
        path="/farm/:farmID/batch-shed-availability"
      />
      <RouteWithLayout
        component={TrailBalanceReportView}
        exact
        layout={MainLayout}
        path="/trail-balance-report"
      />
      <RouteWithLayout
        component={DayBookReportView}
        exact
        layout={MainLayout}
        path="/day-book-report"
      />
      <RouteWithLayout
        component={VendorReportView}
        exact
        layout={MainLayout}
        path="/vendor-report"
      />
      <RouteWithLayout
        component={CommissionReportView}
        exact
        layout={MainLayout}
        path= "/commission-report"
      />
      
      <RouteWithLayout
        component={VendorReportView}
        exact
        layout={MainLayout}
        path="/line-vendor-report"
      />
      <RouteWithLayout
        component={CustomerReportView}
        exact
        layout={MainLayout}
        path="/customer-report"
      />
      <RouteWithLayout
        component={CustomerReportView}
        exact
        layout={MainLayout}
        path="/line-customer-report"
      />
      <RouteWithLayout
        component={VendorBulkPaymentsView}
        exact
        layout={MainLayout}
        path="/vendor-bulk-payments"
      />
      <RouteWithLayout
        component={ReconciliationReportView}
        exact
        layout={MainLayout}
        path="/reconciliation-report"
      />
      <RouteWithLayout
        component={BankStatementReport}
        exact
        layout={MainLayout}
        path="/bank-statement-report"
      />
      <RouteWithLayout
        component={BankStatementReport}
        exact
        layout={MainLayout}
        path="/cash-statement-report"
      />
      <RouteWithLayout
        component={CustomerBulkPaymentsView}
        exact
        layout={MainLayout}
        path="/customer-bulk-payments"
      />
      <RouteWithLayout
        component={SubscriptionsView}
        exact
        layout={MainLayout}
        path="/subscriptions"
      />
      {user !== null && user.accountID !== null && user.accountID === 2 ? (
        <RouteWithLayout
          component={SubscribersView}
          exact
          layout={MainLayout}
          path="/cpanel"
        />
      ) : (
        ""
      )}
      {user !== null && user.Config.taxes?(
          <RouteWithLayout
          component={CustomerTDSReportView}
          exact
          layout={MainLayout}
          path="/customer-tds-report"
        />
        ):null}
      <RouteWithLayout
        component={CustomerTDSReportView}
        exact
        layout={MainLayout}
        path="/line-customer-tds-report"
      />
      {user !== null && user.Config.taxes?(
        <RouteWithLayout
          component={TaxPaymentsView}
          exact
          layout={MainLayout}
          path="/tax-payments"
        />):
      null}
        <RouteWithLayout
          component={TaxPaymentsView}
          exact
          layout={MainLayout}
          path="/line-tax-payments"
        />
        {user !== null && user.Config.taxes?(
        <RouteWithLayout
            component={VendorTDSReportView}
            exact
            layout={MainLayout}
            path="/vendor-tds-report"
          />
        ):null}
      <RouteWithLayout
        component={VendorTDSReportView}
        exact
        layout={MainLayout}
        path="/line-vendor-tds-report"
      />
      <RouteWithLayout
        component={VendorBillBalanceReportView}
        exact
        layout={MainLayout}
        path="/vendor-bill-balance-report"
      />
      <RouteWithLayout
        component={VendorYearlyTaxReportView}
        exact
        layout={MainLayout}
        path="/vendor-yearly-tax-report"
      />
      <RouteWithLayout
        component={VendorYearlyTaxReportView}
        exact
        layout={MainLayout}
        path="/line-vendor-yearly-tax-report"
      />
      {user !== null && user.Config.taxes?(
        <RouteWithLayout
          component={CustomerYearlyTaxReport}
          exact
          layout={MainLayout}
          path="/customer-yearly-tax-report"
        />
        ):null}
      <RouteWithLayout
        component={CustomerYearlyTaxReport}
        exact
        layout={MainLayout}
        path="/line-customer-yearly-tax-report"
      />
            <RouteWithLayout
        component={CustomerBillBalanceReportView}
        exact
        layout={MainLayout}
        path="/customer-bill-balance-report"
      />
      {user !== null && user.Config.feed_section?(
          <RouteWithLayout
            component={StockAdjustmentView}
            exact
            layout={MainLayout}
            path="/stock-adjustments"
          />
        ):null}
      <RouteWithLayout
        component={LineSaleEntriesView}
        exact
        layout={MainLayout}
        path="/line-sale-entries"
      />
      {user !== null && user.Config.lines_sales?(
        <RouteWithLayout
          component={ExpenseTypeView}
          exact
          layout={MainLayout}
          path="/line-expense-type"
        />
        ):null}
        {user !== null && user.Config.lines_sales?(
          <RouteWithLayout
            component={ExpensesView}
            exact
            layout={MainLayout}
            path="/line-expenses"
          />
        ):null}
      <RouteWithLayout
        component={LineReportView}
        exact
        layout={MainLayout}
        path="/line-report"
      />
      <RouteWithLayout
        component={LineSaleReportView}
        exact
        layout={MainLayout}
        path="/line-sale-report"
      />
      <RouteWithLayout
        component={TaxProfiles}
        exact
        layout={MainLayout}
        path="/taxes"
      />
      <RouteWithLayout
        component={StorageReportView}
        exact
        layout={MainLayout}
        path="/storage-report"
      />
      <RouteWithLayout
        component={Configuration}
        exact
        layout={MainLayout}
        path="/config"
      />
      <Redirect from="/" to="/dashboard" />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
