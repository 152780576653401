import React, { useState, useRef } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import withStyles from "@material-ui/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import useTheme from "@material-ui/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import GetAppIcon from "@material-ui/icons/GetApp";
import DateFnsUtils from "@date-io/date-fns";
import {DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import api from "../../common/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import html2canvas from "html2canvas";
import useFromValidator from '../../common/formValidator';
import { useReactToPrint } from 'react-to-print';
import {useGlobalState} from '../../state';
import { changeToLocalString } from '../../common/functions';

import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TableWrapper from "../../components/Table/TableWrapper";
import { generateReportTitle } from "./utils/functions";

window.html2canvas = html2canvas;

const schema = {    
    entryDate:{
        presence: {allowEmpty: false, message: 'is required'}
    }
};

const useStyles = makeStyles(theme => ({
  root: props => ({
    margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
  }),
  topBar: {
    marginBottom: theme.spacing(2)
  },
  link: {
    display: "flex"
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20
  },
  formControl: {
    width: "100%"
  },
  progressContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  },
  progressBar: {
    margin: theme.spacing(2)
  },
  singleLineBorder: {
    borderTop: "solid 1px #F26522",
    borderBottom: "solid 1px #F26522"
  },
  doubleLineBorder: {
    borderTop: "double #F26522",
    borderBottom: "double #F26522"
  },
  reportContainer: {},
  reportHeader: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  rawBatchName: {
    textAlign: "center",
    textDecoration: "underline"
  }
}));

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding:"4px",
    overflow: "hidden", 
    fontSize:"12px",
    width:"77px"  },
  body: {
    //fontSize: "1em",
    backgroundColor: theme.palette.common.white,
    padding:"4px",
    overflow: "hidden", 
    fontSize:"12px",
    width:"77px"  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    },
    "&:last-child td": {
      borderTop: "double "+ theme.palette.warning.dark,
      borderBottom: "double "+ theme.palette.warning.dark,
    }
  }
}))(TableRow);

const tableHeader = JSON.parse(
  `[["Batch","Shed","Date","Age(in Weeks)","Age(in days)","Closing Birds","Egg Production(in Trays)","Broken Eggs(in Trays)", "Damaged Eggs(in Trays)", "Production %", "Good Egg %", "Broken Egg %", "Damaged Egg %", "Feed Intake in Kg",
    "Feed/bird in grams","Egg/bag","Mortality","Std Egg %","Std.Feed/bird in grams","Diff. Egg Production","Diff. Feed/grams"]]`
);

const generateExcel = function(header, data, fileName="report") {
  let csvData = "";
  header.forEach((column, index) => {
    if (index === 0) csvData = csvData + column;
    else csvData = csvData + "," + column;
  });
  csvData = csvData + "\r\n";
  data.forEach(row => {
    row.forEach((column, index) => {
      //Ignore Truck view Data
      if (index === 21 || index === 22 || index === 23 || index === 24 || index === 25 || index === 26) {
        return
      }
      if (index === 0) csvData = csvData + column;
      //else if (index === 1) csvData = csvData + "";
      else csvData = csvData + "," + column;
    });
    csvData = csvData + "\r\n";
  });

  var element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/csv;charset=utf-8," + encodeURIComponent(csvData)
  );
  element.setAttribute("download", `${fileName}.csv`);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

const AllBatchDaily = ({ showError, match }) => {
  const theme = useTheme();
  const [{user}] = useGlobalState();
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);
  const [data, setData] = useState([]);
    const [entryDate, setEntryDate] = useState(null);
    const [reportFileName, setReportFileName] = useState("");

  const [formState, hasError, handleChange] = useFromValidator(schema);

  const [column, setColumn] = useState({
    stdData: false,
    diffData: false,
    truckEggsView: user?.Config?.truckeggs_as_goodEggs || false,
  })

  const parseReportFromServer = function(data) {
    const { reportsArray } = data;
    setData(reportsArray);
    console.log(data);
  };

  //Dynamic Report based on TruckView Switch
  const  truckDynamicReport = function(truckEggsView, row, defaultIndex,truckViewindex, amountObj) {
    if (truckEggsView) {
        return row[truckViewindex] ? changeToLocalString(row[truckViewindex], amountObj) : "";
    } else {
        return row[defaultIndex] ? changeToLocalString(row[defaultIndex], amountObj) : "";
    }
  }
  const generateReport = function() {
    showError("");
    setLoading(true);
    const payload = {
      //batchID: batches[batchIndex].id
      farmID: parseInt(match.params["farmID"]),
      date: formState.values.entryDate
    };

    const response = api.post("reports/all-batch-daily", payload);

    response
      .then(res => {
        parseReportFromServer(res);

        setReportFileName(generateReportTitle({date:formState.values.entryDate, reportName: "AllBatchDaily"}));
      })
      .catch(err => {
        if (err.message) {
          if (err.message === "Bad Request" || err.message === "No data found")
            showError(err.message);
        } else {
          showError("Unknown Error");
        }
      })
      .finally(() => {
        setLoading(false);
        setEntryDate(new Date(payload.date))
      });
  };

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true
  });
  const classes = useStyles({ isDesktop });

  const componentRef = useRef();
  const generatePDF = useReactToPrint({
      content: () => componentRef.current,
      documentTitle: reportFileName,
    });

  const amountObj = {
       /* style: "currency",
        currency: user.currency,*/
        locales: user.locales,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }

  return (
    <div className={classes.root}>
      <Grid container alignItems="flex-start" className={classes.topBar}>
        <Grid>
          <Typography display="block" variant="h3" gutterBottom>
            All Batch KPI Report 
          </Typography>
        </Grid>
      </Grid>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container spacing={3} justify="flex-start" alignItems="center">
          <Grid item>
                <DatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Entry Date"
                    format="dd/MM/yyyy"
                    value={formState.values.entryDate || null}
                    onChange={data => {
                        setValid(true);
                        handleChange({target: {name: 'entryDate', value: data}});
                    }}
                    onFocus={e => e.target.blur()} 
                    animateYearScrolling
                    name="entryDate"
                    error={hasError('entryDate')}
                />
          </Grid>
          <Grid item>
            <Button
              disabled={!valid}
              variant="contained"
              color="primary"
              onClick={generateReport}
            >
              Generate
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={!valid || data.length < 1}
              variant="outlined"
              color="primary"
              onClick={generatePDF}
              endIcon={<PictureAsPdfIcon />}
            >
              Download PDF
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={!valid || data.length < 1}
              variant="outlined"
              color="primary"
              onClick={() => generateExcel(tableHeader, data, reportFileName)}
              endIcon={<GetAppIcon />}
            >
              Download Excel
            </Button>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
      <br />
      <div>
        <FormControlLabel
          control={
            <Switch
              checked={column.stdData}
              onChange={() => setColumn({
                ...column,
                stdData: !column.stdData
              })}
              color="primary"
            />
          }
          label="Std Data"
        />
        <FormControlLabel
          control={
            <Switch
              checked={column.diffData}
              onChange={() => setColumn({
                ...column,
                diffData: !column.diffData
              })}
              color="primary"
            />
          }
          label="Difference Data"
        />
        <FormControlLabel
          control={
            <Switch
              checked={column.truckEggsView}
              onChange={() => setColumn({
                ...column,
                truckEggsView: !column.truckEggsView
              })}
              color="primary"
            />
          }
          label="Truck Eggs (Broken & Damage) in Good Eggs"
        />
      </div>
      {loading ? (
        <div className={classes.progressContainer}>
          <CircularProgress className={classes.progressBar} />
        </div>
      ) : (
        <div id="report-container" className={classes.reportContainer} ref={componentRef}>
          <div className={classes.reportHeader}>
            <Typography
              variant="h4"
              component="h4"
              className={classes.rawBatchName}
            >
                All Batch Daily Report {entryDate !== null ? "" + (entryDate.getDate()) + "/"+(entryDate.getMonth()+1) + "/" + entryDate.getFullYear() : ""}
            </Typography>
          </div>
          <TableWrapper enableKeyboardNavigation={true} focus={data && data.length > 0}>
          <Table style={{ tableLayout: "fixed"}} stickyHeader aria-label="sticky table">
            <TableHead >
              <StyledTableRow>
              {/* <StyledTableCell>Batch</StyledTableCell> */}
              <StyledTableCell>Shed</StyledTableCell>
              {/* <StyledTableCell>Date</StyledTableCell> */}
                <StyledTableCell>Closing Bird</StyledTableCell>
                <StyledTableCell>Egg Production </StyledTableCell>
                <StyledTableCell>Broken Eggs </StyledTableCell>
                <StyledTableCell>Damaged Eggs </StyledTableCell>
                <StyledTableCell>Production %</StyledTableCell>
                <StyledTableCell>Good Egg %</StyledTableCell>
                <StyledTableCell>Broken Egg %</StyledTableCell>
                <StyledTableCell>Damaged Egg %</StyledTableCell>
                <StyledTableCell>Feed Intake in Kg</StyledTableCell>
                <StyledTableCell>Feed/bird in grams</StyledTableCell>
                <StyledTableCell>Egg/bag</StyledTableCell>
                <StyledTableCell>Mortality</StyledTableCell>
                <StyledTableCell>Age (in weeks)</StyledTableCell>
                <StyledTableCell>Age (in days)</StyledTableCell>
                {
                  (column.stdData) && 
                  <>
                      <StyledTableCell>Std Egg %</StyledTableCell>
                      <StyledTableCell>Std. Feed/bird (in grams)</StyledTableCell>
                  </>
                }
                {
                  (column.diffData) && 
                  <>
                      <StyledTableCell>Diff. Egg Production</StyledTableCell>
                      <StyledTableCell>Diff. Feed/grams</StyledTableCell>
                  </>
                }
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell style={{ fontWeight: "bold" }} align="left">
                    {row[1] ? row[1] : ""}
                  </StyledTableCell>
                  <StyledTableCell align="left"> 
                  {row[3]? changeToLocalString(row[3], amountObj) : ""}        
                  </StyledTableCell>
                  <StyledTableCell align="left"> 
                  {truckDynamicReport(column.truckEggsView,row,4,23,amountObj)}   
                  </StyledTableCell>
                  <StyledTableCell align="left"> 
                  {truckDynamicReport(column.truckEggsView,row,5,21,amountObj)}   
                  </StyledTableCell>
                  <StyledTableCell align="left">
                  {truckDynamicReport(column.truckEggsView,row,6,22,amountObj)}   
                  </StyledTableCell>
                  <StyledTableCell align="left">
                  {row[7] ? changeToLocalString(row[7], amountObj) : ""}     
                  </StyledTableCell>
                  <StyledTableCell align="left"> 
                  {truckDynamicReport(column.truckEggsView,row,8,24,amountObj)}   
                  </StyledTableCell>
                  <StyledTableCell align="left"> 
                  {truckDynamicReport(column.truckEggsView,row,9,25,amountObj)}  
                  </StyledTableCell>
                  <StyledTableCell align="left">
                  {truckDynamicReport(column.truckEggsView,row,10,26,amountObj)}  
                  </StyledTableCell>
                  <StyledTableCell align="left">
                  { row[11] ? changeToLocalString(row[11], amountObj) : ""}                    
                  </StyledTableCell>
                  <StyledTableCell align="left">
                  { row[12] ? changeToLocalString(row[12], amountObj) : ""}                    
                  </StyledTableCell>
                  <StyledTableCell align="left">
                  { row[13] ? changeToLocalString(row[13], amountObj) : ""}                    
                  </StyledTableCell>
                  <StyledTableCell align="left">
                  { row[14] ? changeToLocalString(row[14], amountObj) : ""}                    
                  </StyledTableCell>
                  <StyledTableCell align="left">
                  { row[15] ? changeToLocalString(row[15], amountObj) : ""}                    
                  </StyledTableCell>
                  <StyledTableCell align="left">
                  { row[16] ? changeToLocalString(row[16], amountObj) : ""}                    
                  </StyledTableCell>

                  {
                  (column.stdData) && 
                  <>
                  <StyledTableCell align="left">
                  { row[17] ? changeToLocalString(row[17], amountObj) : ""}                    
                  </StyledTableCell>
                  <StyledTableCell align="left">
                  { row[18] ? changeToLocalString(row[18], amountObj) : ""}                    
                  </StyledTableCell>
                  </>
                  }
                  {
                  (column.diffData) && 
                    <>
                    <StyledTableCell align="left">
                    { row[19] ? changeToLocalString(row[19], amountObj) : ""}                    
                    </StyledTableCell>
                    <StyledTableCell align="left">
                    { row[20] ? changeToLocalString(row[20], amountObj) : ""}                    
                    </StyledTableCell>
                    </>
                  } 
                
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          </TableWrapper>
        </div>
      )}
    </div>
  );
};

export default AllBatchDaily;
