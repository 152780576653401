import React from "react";
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import HouseOutlinedIcon from '@material-ui/icons/HouseOutlined';
import ReceiptIcon from '@material-ui/icons/Receipt';

const DASHBOARD = {
    title: "Dashboard",
    href: "/dashboard",
    icon: <DashboardOutlinedIcon />,
  };

const DOCTOR_DASHBOARD = {
  title: "Farm List",
  href: "/dashboard",
  icon: <DashboardOutlinedIcon />,
};

const FARMS = {
    title: "Farms",
    href: "/farm",
    icon: <DashboardOutlinedIcon />,
  };

const DAILYFEED = {
    title: "Daily Feed Production",
    href: "/daily-entry-feed",
    icon: <HouseOutlinedIcon />,
  };

const ACCOUNTING = {
    dashboard : {
        title: "Dashboard",
        href: "/accounts-dashboard",
        isBookmarked: false,
      },
      bankTransaction : {
        title: "Bank Transaction",
        href: "/bank-transaction",
        isBookmarked: false,
      },
    expenses : {
        nested: true,
        title: "Expenses",
        icon: <CreditCardOutlinedIcon />,
        subMenus: [
          {
            title: "Purchases",
            href: "/purchase",
            isBookmarked: false
          },
          {
            title: "Other Expenses",
            href: "/other-expense",
            isBookmarked: false
          },
          {
            title: "Vendor Payments",
            href: "/vendor-bulk-payments",
            isBookmarked: false
          },
          {
            title: "Vendor Report",
            href: "/vendor-report",
            isBookmarked: false
          },
          {
            title: "Vendor Bill Balance Report",
            href:  "/vendor-bill-balance-report",
            isBookmarked: false
          },
        ],
      },
    staffExpenses : {
        nested: true,
        title: "Expenses",
        icon: <CreditCardOutlinedIcon />,
        subMenus: [
          {
            title: "Purchases",
            href: "/purchase",
            isBookmarked: false
          },
          {
            title: "Other Expenses",
            href: "/other-expense",
            isBookmarked: false
          },
          {
            title: "Vendor Payments",
            href: "/vendor-bulk-payments",
            isBookmarked: false
          },
          {
            title: "Vendor Report",
            href: "/vendor-report",
            isBookmarked: false
          },
          {
            title: "Vendor Tax Report",
            href: "/vendor-tds-report",
            isBookmarked: false
          },
          {
            title: "Vendor Bill Balance Report",
            href:  "/vendor-bill-balance-report",
            isBookmarked: false
          },
          {
            title: "Vendor Yearly Tax Report",
            href: "/vendor-yearly-tax-report",
            isBookmarked: false
          },
        ],
      },
    receipts :  {
        nested: true,
        title: "Receipts",
        icon: <AttachMoneyOutlinedIcon />,
        subMenus: [
          {
            title: "Sales",
            href: "/sale",
            isBookmarked: false
          },
          {
            title: "Other Income",
            href: "/other-income",
            isBookmarked: false
          },
          {
            title: "Customer Payments",
            href: "/customer-bulk-payments",
            isBookmarked: false
          },
          {
            title: "Customer Report",
            href: "/customer-report",
            isBookmarked: false
          },
          {
            title: "Customer Bill Balance Report",
            href: "/customer-bill-balance-report",
            isBookmarked: false
          },
        ],
      },
    staffReceipts : {
        nested: true,
        title: "Receipts",
        icon: <AttachMoneyOutlinedIcon />,
        subMenus: [
          {
            title: "Sales",
            href: "/sale",
            isBookmarked: false
          },
          {
            title: "Other Income",
            href: "/other-income",
            isBookmarked: false
          },
          {
            title: "Customer Bulk Payments",
            href: "/customer-bulk-payments",
            isBookmarked: false
          },
          {
            title: "Customer Report",
            href: "/customer-report",
            isBookmarked: false
          },
          {
            title: "Customer TAX Report",
            href: "/customer-tds-report",
            isBookmarked: false
          },
          {
            title: "Customer Bill Balance Report",
            href: "/customer-bill-balance-report",
            isBookmarked: false
          },
          {
            title: "Customer Yearly Tax Report",
            href: "/customer-yearly-tax-report",
            isBookmarked: false
          },
        ],
      },
    dailyEggRates :  {
        title: "Daily Egg Rates",
        href: "/daily-egg-rates",
        isBookmarked: false
      },
    reports : {
        nested: true,
        title: "Reports",
        icon: <PictureAsPdfOutlinedIcon />,
        subMenus: [
          {
            title: "P/L Report",
            href: "/trail-balance-report",
            isBookmarked: false
          },
          {
            title: "Day Book Report",
            href: "/day-book-report",
            isBookmarked: false
          },
          {
            title: "Reconciliation Report",
            href: "/reconciliation-report",
            isBookmarked: false
          },
          {
            title: "Bank Statement Report",
            href: "/bank-statement-report",
            isBookmarked: false
          },
          {
            title: "Cash Statement Report",
            href: "/cash-statement-report",
            isBookmarked: false
          },
          {
            title: "Commission Report",
            href: "/commission-report",
            isBookmarked: false
          }
        ],
      },
    purchases : {
      nested: true,
      title: "Expenses",
      icon:  <CreditCardOutlinedIcon />,
      subMenus: [
        {
          title: "Purchases",
          href: "/purchase",
          isBookmarked: false
        },
        {
          title: "Other Expenses",
          href: "/other-expense",
          isBookmarked: false
        },
      ],
    },
    sales : {
      nested: true,
      title: "Receipts",
      icon:<AttachMoneyOutlinedIcon />,
      subMenus: [
        {
          title: "Sales",
          href: "/sale",
          isBookmarked: false
        },
        {
          title: "Other Income",
          href: "/other-income",
          isBookmarked: false
        },
      ],
    }
  };

const FEED_MILL = {
    nested: true,
    title: "Feed Mill",
    icon: <HouseOutlinedIcon />,
    subMenus: [
      {
        title: "Feed Formulation",
        href: "/feed-formulation",
        isBookmarked: false
      },
      {
        title: "Feed Formulation Storage",
        href: "/feed-formulation-storage",
        isBookmarked: false
      },
      {
        title: "Move to shed Feed storage",
        href: "/feed-storage-stock",
        isBookmarked: false
      },
      {
        title: "Raw Material Storage",
        href: "/raw-material-storage",
        isBookmarked: false
      },
      {
        title: "Move from Raw material storage",
        href: "/raw-material-storage-stock",
        isBookmarked: false
      },
      {
        title: "Daily Feed Production",
        href: "/daily-entry-feed",
        isBookmarked: false
      },
      {
        title: "Raw Materials Report",
        href: "/reports/rawmaterials",
        isBookmarked: false
      },
      {
        title: "Stock Adjustments",
        href: "/stock-adjustments",
        isBookmarked: false
      },
      {
        title: "Stock Statement",
        href: "/reports/stockStatement",
        isBookmarked: false
      },
      {
        title: "Stock Availability Report",
        href: "/reports/stockAvailability",
        isBookmarked: false
      },
      {
        title: "Material Nutrition Profile",
        href: "/material-nutrition-profile",
        isBookmarked: false
      },
    ],
  };

const TAXES = {
    nested: true,
    title: "Taxes",
    icon: <ReceiptIcon />,
    subMenus: [
        {
          title: "TCS/TDS Payments",
          href: "/tax-payments",
          isBookmarked: false
        },
        {
          title: "Customer TAX Report",
          href: "/customer-tds-report",
          isBookmarked: false
        },
        {
          title: "Vendor Tax Report",
          href: "/vendor-tds-report",
          isBookmarked: false,
        },
        {
          title: "Customer Yearly Tax Report",
          href: "/customer-yearly-tax-report",
          isBookmarked: false
        },
        {
          title: "Vendor Yearly Tax Report",
          href: "/vendor-yearly-tax-report",
          isBookmarked: false,
        },
    ]
  };

const LINE_SALES = {
    sales : {
        title: "Sales",
        href: "/line-sale",
        isBookmarked: false
      },
    truck : {
        title: "Truck",
        href: "/line-sale-truck",
        isBookmarked: false
      },
    expense_type : {
        title: "Expense Type",
        href: "/line-expense-type",
        isBookmarked: false
      },
    expenses : {
        title: "Expenses",
        href: "/line-expenses",
        isBookmarked: false
      },
    customerVendorDetails : {
        title: "Customer/Vendor Details",
        href: "/line-customer-vendor",
        isBookmarked: false
    },
    purchases : {
        title: "Purchases",
        href: "/line-purchase",
        isBookmarked: false
    },
    lineSaleBulkPayment : {
        title: "Line Sale Bulk Payments",
        href: "/line-sale-bulk-payments",
        isBookmarked: false
      },
    linePurchaseBulkPayment : {
        title: "Line Purchase Bulk Payments",
        href: "/line-purchase-bulk-payments",
        isBookmarked: false
      },
    reports : {
        nested: true,
        title: "Reports",
        icon: <PictureAsPdfOutlinedIcon />,
        subMenus: [
          {
            title: "Line Sales Report",
            href: "/line-sale-report",
            isBookmarked: false
          },
          {
            title: "Storage Report",
            href: "/storage-report",
            isBookmarked: false
          },
          {
            title: "Line Report",
            href: "/line-report",
            isBookmarked: false
          },
          {
            title: "Line Customer Report",
            href: "/line-customer-report",
            isBookmarked: false
          },
          {
            title: "Line Vendor Report",
            href: "/line-vendor-report",
            isBookmarked: false
          },
        ],
      },
    taxes : {
        nested: true,
        title: "Taxes",
        icon: <ReceiptIcon />,
        subMenus: [
            {
              title: "TCS/TDS Payments",
              href: "/line-tax-payments",
              isBookmarked: false
            },
            {
              title: "Customer TAX Report",
              href: "/line-customer-tds-report",
              isBookmarked: false
            },
            {
              title: "Vendor Tax Report",
              href: "/line-vendor-tds-report",
              isBookmarked: false,
            },
            {
              title: "Customer Yearly Tax Report",
              href: "/line-customer-yearly-tax-report",
              isBookmarked: false
            },
            {
              title: "Vendor Yearly Tax Report",
              href: "/line-vendor-yearly-tax-report",
              isBookmarked: false,
            },
        ]
      },
    lineSaleDriver : {
        title: "Add line sales",
        href: "/line-sale-driver",
        icon: <PictureAsPdfOutlinedIcon />,
        isBookmarked: false,
      }
  };

const SETTINGS = {
    breeds : {
        title: "Breeds",
        href: "/breed",
        isBookmarked: false
      },
    materialMaster : {
        title: "Material Master",
        href: "/material_master",
        isBookmarked: false
    },
    customerVendorDetails : {
        title: "Customer/Vendor Details",
        href: "/customer-vendor-details",
        isBookmarked: false
    },
    invoiceSettings : {
        title: "Invoice Settings",
        href: "/invoice_settings",
        isBookmarked: false
    },
    expenseType : {
        title: "Expense Type",
        href: "/expense-type",
        isBookmarked: false
    },
    incomeType : {
        title: "Income Type",
        href: "/income-type",
        isBookmarked: false
    },
    banks : {
        title: "Banks",
        href: "/bank",
        isBookmarked: false
    },
    lines : {
        title: "Lines",
        href: "/line_name",
        isBookmarked: false
    },
    subUsers : {
        title: "Sub Users",
        href: "/subuser",
        isBookmarked: false
    },
    billing : {
        title: "Billing",
        href: "/subscriptions",
        isBookmarked: false
      },
    taxProfiles : {
        title: "Tax Profiles",
        href: "/taxes",
      },
    profile : {
        title: "Profile",
        href: "/profilepage",
        isBookmarked: false
    },
    config : {
        title: "Configuration",
        href: "/config",
      },
    doctorProfile : {
        title: "Profile",
        href: "/doctorprofilepage",
        icon: <SettingsOutlinedIcon />,
      }
  };

const CPANEL = {
  title: "Cpanel",
  href: "/cpanel",
};

const removeThisPage = (_pages,title=false,url=false)=>{
    for(const [index,item] of Object.entries(_pages)){
        if((title && item.title === title) || (url && item.href === url)){
            _pages.splice(index,1)
            break;
        }
    }
    return _pages;
};

export {
    DASHBOARD,
    DOCTOR_DASHBOARD,
    FARMS,
    ACCOUNTING,
    FEED_MILL,
    TAXES,
    LINE_SALES,
    SETTINGS,
    CPANEL,
    removeThisPage,
    DAILYFEED
};