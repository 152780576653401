import React, { useState, useRef } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import withStyles from "@material-ui/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import useTheme from "@material-ui/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import GetAppIcon from "@material-ui/icons/GetApp";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider,KeyboardDatePicker } from "@material-ui/pickers";
import api from "../../common/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import html2canvas from "html2canvas";
import {useGlobalState} from '../../state';
import useFromValidator from '../../common/formValidator';
import { changeToLocalString, generatePdfFile } from '../../common/functions';

import { version } from "../../common/enums";
import { Redirect } from "react-router-dom";
import { Chip } from "@material-ui/core";
import ErrorIcon from '@material-ui/icons/Error';
import TableWrapper from "../../components/Table/TableWrapper";
import { generateReportTitle } from "./utils/functions";
window.html2canvas = html2canvas;
const schema = {    
  entryDate:{
      presence: {allowEmpty: false, message: 'is required'}
  },
  endDate:{
    presence: {allowEmpty: false, message: 'is required'}
},
};
const useStyles = makeStyles(theme => ({
  root: props => ({
    margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
  }),
  topBar: {
    marginBottom: theme.spacing(2)
  },
  link: {
    display: "flex"
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20
  },
  formControl: {
    width: "100%"
  },
  progressContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  },
  progressBar: {
    margin: theme.spacing(2)
  },
  singleLineBorder: {
    borderTop: "solid 1px #F26522",
    borderBottom: "solid 1px #F26522",
    fontWeight:'bold'
  },
  doubleLineBorder: {
    borderTop: "double #F26522",
    borderBottom: "double #F26522"
  },
  boldCell:{
    fontWeight:'bold'
  },
  endDateContainer:{
    display: "flex",
  },
  reportContainer: {},
  reportHeader: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  rawBatchName: {
    textAlign: "center",
    textDecoration: "underline",
    marginBottom: theme.spacing(2)
  },
  reminderTables: {
    padding: theme.spacing(2),
  },
  doctorNotesTables: {
    padding: theme.spacing(2),
  }
}));

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding:"6px",
    overflow: "hidden", 
    width:"90px",
  },
  body: {
    fontSize: "1em",
    backgroundColor: theme.palette.common.white,
    padding:"6px",
    overflow: "hidden", 
    width:"90px",
    textWrap: "nowrap",
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
    "& .MuiTableCell-root": {
      borderLeft: "1px solid rgba(224, 224, 224, 1)",
    }
  }
}))(TableRow);

const tableHeader = JSON.parse(
  `[["Shed", "Age","Good Eggs", "Other Eggs", "Total Eggs", "Egg %", "Egg Std",  "Feed In Kg", "Feed In gm" ,"Feed Std", "gm/Egg", "Eggs/Bag", "Mort.", "Culls", "Closing Birds", "Feed Value", "Egg Rate", "Egg Value", "Balance", "Cost/Egg", "Cost/Bird", "Lab Expense", "Medicine Expense", "Other Expense"]]
  `
);

const summaryTableHeader = JSON.parse(
  `[["", "Total Eggs", "Egg %", "Feed In Kg", "Feed In gm", "Closing Birds", "Feed Value", "Egg Value", "Balance", "Cost/Egg", "Labour Expense", "Medicine Expense", "Other Expense"]]
  `
);

//const purchasesTableHeader = "Date, Item, Bill Value"
const generateExcel = function(header, data, summaryHeader, summaryData, fileName="report") {
  console.debug(header, data)
  let csvData = "";
  header.forEach((column, index) => {
    if (index === 0) csvData = csvData + column;
    else csvData = csvData + "," + column;
  });
  console.debug(csvData)
  csvData = csvData + "\r\n";
  console.debug(csvData)
  data.forEach(row => {
    row.forEach((column, index) => {
      if (index === 0) csvData = csvData + column;
      else csvData = csvData + "," + column;
    });
    csvData = csvData + "\r\n";
  });

    // Add blank lines to separate the tables
    csvData = csvData + "\r\n\r\n";

    // Add summary table header
    summaryHeader.forEach((column, index) => {
      if (index === 0) csvData = csvData + column;
      else csvData = csvData + "," + column;
    });
    csvData = csvData + "\r\n";
  
    // Add summary table data
    summaryData.forEach(row => {
      row.forEach((column, index) => {
        if (index === 0) csvData = csvData + column;
        else csvData = csvData + "," + column;
      });
      csvData = csvData + "\r\n";
    });

  var element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/csv;charset=utf-8," + encodeURIComponent(csvData)
  );
  element.setAttribute("download", `${fileName}.csv`);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

const DailyProfitLoss = ({ showError, match }) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [showNoDataError, setShowNoDataError] = useState(false);
    const [entryDate, setEntryDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [reportFileName, setReportFileName] = useState("");


    const [formState, hasError, handleChange] = useFromValidator(schema);


  const parseReportFromServer = function(data) {
    const { reportsArray, reportSummaryArray } = data;

    setData(reportsArray);
    setSummaryData(reportSummaryArray);

    if(!reportsArray || reportsArray.length === 0) {
      setShowNoDataError(true);
      return
    }
    setShowNoDataError(false);

    console.log(data);
  };


  const [{user}] = useGlobalState();
    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }



  const generateReport = function() {
    showError("");
    setLoading(true);
    const payload = {
      farmID: parseInt(match.params["farmID"]),
      date: formState.values.entryDate,
      endDate:formState.values.endDate,
    };

    const response = api.post("reports/daily-Profit-loss", payload);

    response
      .then(res => {
        parseReportFromServer(res);
        setReportFileName(generateReportTitle({date: formState.values.entryDate,lastDate:formState.values.endDate, reportName: "DailyProfitLoss"}));
      })
      .catch(err => {
        if (err.message) {
          if (err.message === "Bad Request" || err.message === "No data found")
            showError(err.message);
        } else {
          showError("Unknown Error");
        }
      })
      .finally(() => {
        setLoading(false);
        setEntryDate(new Date(payload.date))
        setEndDate(new Date(payload.endDate))
      });
  };


  const isDesktop = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true
  });
  const classes = useStyles({ isDesktop });

  const componentRef = useRef();
  const generatePDF = async () => {
    const payload = {
      farmID: parseInt(match.params["farmID"]),
      date: formState.values.entryDate,
      endDate:formState.values.endDate,
    };
    generatePdfFile(
      payload, 
      "/pdf/daily-profit-loss", 
      reportFileName,
    );
  };

  if(user.versionID === (version.lite)){
        return(
            <Redirect to="/not-found" />
        );
  }
  return (
    <div className={classes.root}>
      <Grid container alignItems="flex-start" className={classes.topBar}>
        <Grid>
          <Typography display="block" variant="h3" gutterBottom>
            Daily Profit and Loss Report
          </Typography>
        </Grid>
      </Grid>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container spacing={3} justify="flex-start" alignItems="center">
        <Grid item xs={6}>
               <div className={classes.endDateContainer}>

                    <Grid item>
                      <KeyboardDatePicker
                          margin="normal"
                          autoOk
                          variant="inline"
                          label="From"
                          format="dd/MM/yyyy"
                          value={formState.values.entryDate || null}
                          onChange={data => {
                              setEntryDate(data)
                              handleChange({target: {name: 'entryDate', value: data}});
                          }}
                          KeyboardButtonProps={{
                              'aria-label': 'change date',
                          }}
                          onFocus={e => e.target.blur()} 
                          animateYearScrolling
                          name="entryDate"
                          error={hasError('entryDate')}
                      />
                  </Grid>

                  <Grid item>
                      <KeyboardDatePicker
                          margin="normal"
                          autoOk
                          variant="inline"
                          label="To"
                          format="dd/MM/yyyy"
                          value={formState.values.endDate || null}
                          onChange={data => {
                            setEndDate(data)
                            handleChange({target: {name: 'endDate', value: data}});
                        }}
                          KeyboardButtonProps={{
                              'aria-label': 'change date',
                          }}
                          onFocus={e => e.target.blur()} 
                          animateYearScrolling
                          name="endDate"
                          style={{marginLeft:"10px"}}
                          error={hasError('endDate')}
                      />
                  </Grid>

                </div>
                
          </Grid>


          <Grid item>
            <Button
              disabled={!formState.isValid}
              variant="contained"
              color="primary"
              onClick={generateReport}
            >
              Generate
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={!formState.isValid || !data || data.length === 0}
              variant="outlined"
              color="primary"
              onClick={generatePDF}
              endIcon={<PictureAsPdfIcon />}
            >
              Download PDF
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={!formState.isValid || !data || data.length === 0}
              variant="outlined"
              color="primary"
              onClick={() => generateExcel(tableHeader, data, summaryTableHeader, summaryData, reportFileName)}
              endIcon={<GetAppIcon />}
            >
              Download Excel
            </Button>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
      {loading ? (
        <div className={classes.progressContainer}>
          <CircularProgress className={classes.progressBar} />
        </div>
      ) : (
        <div id="report-container" className={classes.reportContainer} ref={componentRef}>
          <div className={classes.reportHeader}>
            <Typography
              variant="h4"
              component="h4"
              className={classes.rawBatchName}
            >
              Daily Profit and Loss Report <br></br>
              {entryDate !== null ? "From" : ""} {entryDate !== null ? "" + (entryDate.getDate()) + "/"+(entryDate.getMonth()+1) + "/" + entryDate.getFullYear() : ""}  {endDate !== null ? "To " : ""}{endDate !== null ? "" + (endDate.getDate()) + "/"+(endDate.getMonth()+1) + "/" + endDate.getFullYear() : ""}
            </Typography>
            {/* if there is no data on selected date show "No data found"*/}
            {showNoDataError && (
              <Chip size="medium" color="secondary" icon={<ErrorIcon/>} label={"No data found"}/>
            )}
          </div>
          <TableWrapper enableKeyboardNavigation={true} focus={data && data.length > 0}>
          <Table 
          stickyHeader aria-label="sticky table">
            <TableHead>
            <StyledTableRow
           align="center" >
                <StyledTableCell colSpan={2}></StyledTableCell>

                <StyledTableCell  align="center" colSpan={5}>Eggs</StyledTableCell>
                <StyledTableCell  align="center" colSpan={5}>Feed</StyledTableCell>
                <StyledTableCell align="center" colSpan={3}>Birds</StyledTableCell>
                <StyledTableCell align="center" colSpan={6}>Cost</StyledTableCell>
                <StyledTableCell align="center" colSpan={3}>Expense</StyledTableCell>

              </StyledTableRow>
              <StyledTableRow
              align="center">
                {/* <StyledTableCell align="center">Batch</StyledTableCell> */}
                <StyledTableCell align="center">Shed</StyledTableCell>
                <StyledTableCell align="center">Age</StyledTableCell>
                <StyledTableCell align="center">Good</StyledTableCell>
                <StyledTableCell align="center">Other</StyledTableCell>
                <StyledTableCell align="center">Total</StyledTableCell>

                <StyledTableCell align="center">%</StyledTableCell>
                <StyledTableCell align="center">Std</StyledTableCell>

                <StyledTableCell align="center">Kg</StyledTableCell>
                <StyledTableCell align="center">gm</StyledTableCell>
                <StyledTableCell align="center">Std</StyledTableCell>
                <StyledTableCell align="center">gm /Egg</StyledTableCell>
                <StyledTableCell align="center">Eggs /Bag</StyledTableCell>
                <StyledTableCell align="center">Mort.</StyledTableCell>
                <StyledTableCell align="center">Culls</StyledTableCell>
                <StyledTableCell align="center">Closing</StyledTableCell>
                <StyledTableCell align="center">Feed Value</StyledTableCell>
                <StyledTableCell align="center">Egg Rate</StyledTableCell>
                <StyledTableCell align="center">Egg Value</StyledTableCell>
                <StyledTableCell align="center">Balance</StyledTableCell>
                <StyledTableCell align="center">Cost /Egg</StyledTableCell>
                <StyledTableCell align="center">Cost /Bird</StyledTableCell>
                <StyledTableCell align="center" >Labour</StyledTableCell>
                <StyledTableCell align="center">Medicine</StyledTableCell>
                <StyledTableCell align="center">Other</StyledTableCell>
              </StyledTableRow>
            </TableHead>
           <TableBody>
              {data && data.length > 0 && data.map((row, index) => {
                 if((row[0] === "LAYER") || (row[0] === "GROWER ") || (row[0] === "CHICK ")) {

                  return (
                    <StyledTableRow key={index}>
                        <StyledTableCell  align="left"   key={1} colSpan={27} 
                        style={{textAlign:"left !important",fontWeight:"600",fontSize: "14px",
                      padding: "8px"}}>
                      
                      {
                        row[0]
                      }
                      
                        </StyledTableCell>
                    </StyledTableRow>
                  )
                } else {
                  return (
                  <StyledTableRow key={index}>
                  {row.map((cols, col) => {
                      return (
                      <StyledTableCell align="left" key = {col}
                      className={
                          row[0] === "" ? classes.singleLineBorder : undefined
                        }>
                        {
                            col === 15 ||   col === 16 || col === 17 || col === 18 || col === 19 || col === 20 || col === 21 || col === 22 || col === 23 ?
                            (
                            (!isNaN(cols) && cols.toString().indexOf('.') !== -1)?
                                changeToLocalString(cols, user.locales,amountObj)
                                :
                                cols.toLocaleString(user.locales,amountObj))
                                :
                                (
                                (!isNaN(cols) && cols.toString().indexOf('.') !== -1)?
                                changeToLocalString(cols, user.locales)
                                :
                                cols.toLocaleString(user.locales))
                        }
                      </StyledTableCell>
                      )
                      
                  })}
                  </StyledTableRow>
                  )
                }
      })}
            </TableBody>
          </Table>
          </TableWrapper>
          <br />
          {summaryData && summaryData.length > 0 && (
            <>
              <div className={classes.reportHeader}>
                <Typography
                  variant="h4"
                  component="h4"
                  className={classes.rawBatchName}
                >
                  Total Production Report<br></br>
                  {entryDate !== null ? "From" : ""}  {entryDate !== null ? ""  +(entryDate.getDate()) + "/"+(entryDate.getMonth()+1) + "/" + entryDate.getFullYear() : ""} {endDate !== null ? "To" : ""} {endDate !== null ? "" + (endDate.getDate()) + "/"+(endDate.getMonth()+1) + "/" + endDate.getFullYear() : ""}
                </Typography>
              </div>
              <TableWrapper enableKeyboardNavigation={true}>
              <Table 
              stickyHeader aria-label="sticky table">
                <TableHead>
                <StyledTableRow
                  align="center" >
                    <StyledTableCell colSpan={1}></StyledTableCell>

                    <StyledTableCell  align="center" colSpan={2}>Eggs</StyledTableCell>
                    <StyledTableCell  align="center" colSpan={2}>Feed</StyledTableCell>
                    <StyledTableCell  align="center" colSpan={1}>Birds</StyledTableCell>
                    <StyledTableCell align="center" colSpan={4}>Cost</StyledTableCell>
                    <StyledTableCell align="center" colSpan={3}>Expense</StyledTableCell>

                  </StyledTableRow>
                  <StyledTableRow
                  align="center">
                    <StyledTableCell align="center"></StyledTableCell>

                    <StyledTableCell align="center">Total</StyledTableCell>
                    <StyledTableCell align="center">%</StyledTableCell>

                    <StyledTableCell align="center">Kg</StyledTableCell>
                    <StyledTableCell align="center">gm</StyledTableCell>

                    <StyledTableCell align="center">Closing</StyledTableCell>

                    <StyledTableCell align="center">Feed Value</StyledTableCell>
                    <StyledTableCell align="center">Egg Value</StyledTableCell>
                    <StyledTableCell align="center">Balance</StyledTableCell>
                    <StyledTableCell align="center">Cost /Egg</StyledTableCell>

                    <StyledTableCell align="center" >Labour</StyledTableCell>
                    <StyledTableCell align="center">Medicine</StyledTableCell>
                    <StyledTableCell align="center">Other</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
              <TableBody>
                  {summaryData.map((row, index) => {
                    return (
                      <StyledTableRow key={index}>
                      {row.map((cols, col) => {
                          return (
                            <StyledTableCell align="left" key={col}
                            className={
                              `${row === summaryData[summaryData.length -1] ? classes.singleLineBorder : ''} ` +
                              `${(cols === "Layer" || cols === "Grower" || cols === "Chick") ? classes.boldCell : ''}`
                            }>
                            {
                              // Index meanings:
                              // 0: shed type, 1: total eggs, 2: egg %, 3: feed in kg, 4: feed in gram, 5: closing birds,
                              // 6: feed value, 7: egg value, 8: balance, 9: cost per egg, 10: labour cost, 11: medicine cost, 12: other cost.
                              // Append currency symbol to monetary values.
                              [6, 7, 8, 9, 10, 11, 12].includes(col) ?
                              (
                                  (!isNaN(cols) && cols.toString().indexOf('.') !== -1) ?
                                  changeToLocalString(cols, user.locales, amountObj) :
                                  cols.toLocaleString(user.locales, amountObj)
                              ) :
                              (
                                  (!isNaN(cols) && cols.toString().indexOf('.') !== -1) ?
                                  changeToLocalString(cols, user.locales) :
                                  cols.toLocaleString(user.locales)
                              )
                            }
                          </StyledTableCell>
                          )
                          
                      })}
                      </StyledTableRow>
                    )
                  })}
              </TableBody>
              </Table>
              </TableWrapper>
            </>
          )}

        </div>
      )}
    </div>
  );
};

export default DailyProfitLoss;
