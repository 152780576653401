import React from 'react'
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100%',
    overflowX: 'auto',
    '@media print': {
        overflowX: 'visible',
    },
  },
});

function TableWrapper({ children, className, focus, enableKeyboardNavigation, ...rest }) {
  const classes = useStyles();
  const tableRef = React.useRef(null);

  // focus on table when focus prop is true, this will allow users to control table using arrow keys
  React.useEffect(() => {
    if (focus && tableRef.current) {
      tableRef.current.focus();
    }
  }, [focus]);

  return (
    <div 
      className={`${classes.root}
      ${className || ''}`.trim()} ref={tableRef}
      tabIndex={enableKeyboardNavigation ? 0 : -1}
      {...rest}
    >
      {children}
    </div>
  )
}

export default TableWrapper